export const environment = {
  name: 'PROD',
  production: true,
  traceSession: true,
  kcRealmUrl: 'https://auth.wastics.eu/',
  kcRealm: 'Wastics',
  apiUrl: 'https://awk-api.wastics.eu/awk-service/api/v1',
  awkExcelApi: 'https://excel9vb78nuh.wastics.eu/v1/data',
  puppeteerApiUrl: 'https://pdff7qz5ptu.wastics.eu',
  quarkusApiUrl: 'https://dwrm87taviyo.wastics.eu/v1',
  matchMakingApiUrl: 'https://ma25xzr882.wastics.eu',
  notifications: {
    publicKey:
      'BHi_tQtCSCfVgU0lgFxt5zB3bCmqsOre_Eke1bVDGu3y4IZTIwWsndbr8WC2_LdF8DYaKgbD-S6FedUcOKn8OCA',
  },
  umamiId: '891ccccf-74bf-4f2d-bc98-3f61412d6470',
};
